<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
      :src="$helpers.imageHelper(user.to_user_picture ? user.to_user_picture : user.picture)"
      :badge="isChatContact"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user)"
      variant="transparent"
    />
    <div
      v-if="!suggestedUser"
      class="chat-info flex-grow-1"
      :class="{ 'd-flex align-items-center': user.to_user_name }"
    >
      <h5 class="mb-0">
        {{ user.customer_name ? user.customer_name : user.to_user_name }}
      </h5>
      <p
        v-if="user.order_number"
        class="card-text text-truncate"
      >
        {{ $t('Order Number:') }} {{ user.order_number }}
      </p>
    </div>
    <div
      v-if="suggestedUser"
      class="chat-info flex-grow-1 d-flex align-items-center"
    >
      <h5 class="mb-0">
        {{ user.first_name }} {{ user.last_name }}
      </h5>
      <!-- <p class="card-text text-truncate">
        {{ $t('Mobile:') }} {{ user.mobile }}
      </p> -->
    </div>
    <div
      v-if="!suggestedUser && user.to_read"
      class="chat-meta text-nowrap"
    >
      <b-badge
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.to_read }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
    suggestedUser: {
      type: Boolean,
      dedfault: false,
    },
  },
  setup() {
    return { formatDateToMonthShort }
  },
  methods: {
    resolveAvatarBadgeVariant(user) {
      // eslint-disable-next-line no-prototype-builtins
      if (user.hasOwnProperty('online')) {
        if (user.online === 1) return 'success'
        return 'warning'
      }
      if (user.to_user_online === 1) return 'success'
      return 'warning'
    },
  },
}
</script>

<style></style>
