<template>
  <div
    class="user-profile-sidebar"
    :class="{ show: shallShowActiveChatContactSidebar }"
  >
    <!-- Header -->
    <header
      v-if="contact"
      class="user-profile-header h-100"
    >
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <!-- <div class="header-profile-sidebar h-100">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            size="70"
            :src="contact.avatar"
          />
          <span
            class="avatar-status-xl"
            :class="`avatar-status-${contact.status}`"
          />
        </div>
        <h4 class="chat-user-name">
          {{ activeContact.customer_name }}
        </h4>
        <span class="user-post text-capitalize">Order Number: {{ activeContact.order_number }}</span>
        <span class="user-post text-capitalize">Order Type: {{ activeContact.order_type }}</span>
        <span class="user-post text-capitalize">Payment Mode: {{ activeContact.payment_mode }}</span>
        <span class="user-post text-capitalize">Order Status: {{ activeContact.status }}</span>
        <span class="user-post text-capitalize">Service: {{ activeContact.admin_service }}</span>
      </div> -->
    </header>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BAvatar,
  },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: [Object, Array],
      required: true,
    },
    activeContact: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style></style>
