<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <div
        class="sidebar-content"
        :class="{ show: mqShallShowLeftSidebar }"
      >
        <!-- Sidebar close icon -->
        <span class="sidebar-close-icon">
          <feather-icon
            icon="XIcon"
            size="16"
            @click="$emit('update:mq-shall-show-left-sidebar', false)"
          />
        </span>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="d-flex align-items-center w-100">
            <div class="sidebar-profile-toggle">
              <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :src="$helpers.imageHelper(userInfo.picture)"
                variant="transparent"
                badge
                :badge-variant="userInfo.online ? 'success' : 'danger'"
                @click.native="$emit('show-user-profile')"
              />
            </div>
            <!-- Search -->
            <b-input-group class="input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-if="!isUser"
                v-model="searchQuery"
                placeholder="Search..."
              />
              <b-form-input
                v-if="isUser"
                placeholder="Search..."
                @keyup="beginSearch($event)"
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

          <!-- Chats Title -->
          <div
            v-if="isUser && searchUsers.length > 0"
            class="d-flex justify-content-between align-items-center pr-2"
          >
            <h4 class="chat-list-title mt-2">
              {{ $t('Suggested Users') }}
            </h4>
          </div>

          <!-- Chats -->
          <ul
            v-if="isUser && searchUsers.length > 0"
            class="chat-users-list chat-list media-list"
          >
            <chat-contact
              v-for="contact in searchUsers"
              :key="contact.id"
              :suggested-user="true"
              :user="contact"
              tag="li"
              :class="{ active: activeChatContactId === contact.id }"
              is-chat-contact
              @click="$emit('open-chat', contact)"
            />
          </ul>

          <!-- Chats Title -->
          <div class="d-flex justify-content-between align-items-center pr-2">
            <h4 class="chat-list-title mt-2">
              {{ $t('Chats') }}
            </h4>
            <feather-icon
              icon="RefreshCwIcon"
              style="cursor: pointer;"
              size="20"
              @click="$emit('reload-contacts')"
            />
          </div>

          <!-- Chats -->
          <ul class="chat-users-list chat-list media-list">
            <chat-contact
              v-for="contact in filteredChatsContacts"
              :key="contact.id"
              :user="contact"
              tag="li"
              :class="{ active: activeChatContactId === contact.id }"
              is-chat-contact
              @click="$emit('open-chat', contact)"
            />
          </ul>
        </vue-perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BInputGroup, BInputGroupPrepend, BFormInput } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, computed } from '@vue/composition-api'
import { useUserUi } from '@/views/users/useUser'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import ChatContact from './ChatContact.vue'
import UserProfileSidebar from './UserProfileSidebar.vue'

export default {
  components: {
    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,

    // 3rd party
    VuePerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: Number,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    isUser: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      searchUsers: [],
    }
  },
  computed: {
    userInfo() {
      return window.SuperApp.getters.userInfo()
    },
  },
  methods: {
    beginSearch($event) {
      if (!$event.target.value) {
        this.searchUsers = []
        return
      }
      const { searchUser } = useUserUi()
      showLoader()
      searchUser($event.target.value)
        .then(({ data }) => {
          hideLoader()
          if (data.success) {
            this.searchUsers = data.data
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)

    // Search Query
    const searchQuery = ref('')

    const searchFilterFunction = contact => `${contact.customer_name ? contact.customer_name : contact.to_user_name}`.toLowerCase().includes(searchQuery.value.toLowerCase())
    const filteredChatsContacts = computed(() => props.chatsContacts.filter(searchFilterFunction))
    const filteredContacts = computed(() => props.contacts.filter(searchFilterFunction))

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,
      filteredContacts,

      // UI
      resolveChatContact,
      perfectScrollbarSettings,
    }
  },
}
</script>
