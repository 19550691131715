<template>
  <div
    class="chat-profile-sidebar"
    :class="{ show: shallShowUserProfileSidebar }"
  >
    <!-- Header -->
    <header class="chat-profile-header">
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('close-sidebar')"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
            size="70"
            :src="$helpers.imageHelper(profileUserData.picture)"
          />
          <span
            class="avatar-status-xl"
            :class="`avatar-status-${profileUserData.online ? 'online' : 'offline'}`"
          />
        </div>
        <h4 class="chat-user-name">
          {{ profileUserData.first_name }} {{ profileUserData.last_name }}
        </h4>
        <span class="user-post text-capitalize">{{ profileUserData.store_name }}</span>
      </div>
    </header>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
  },
  props: {
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const userStatusOptions = [
      { text: 'Active', value: 'online', radioVariant: 'success' },
      { text: 'Do Not Disturb', value: 'busy', radioVariant: 'danger' },
      { text: 'Away', value: 'away', radioVariant: 'warning' },
      { text: 'Offline', value: 'offline', radioVariant: 'secondary' },
    ]

    return {
      perfectScrollbarSettings,
      userStatusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
#user-status-options ::v-deep {
  .custom-radio {
    margin-bottom: 1rem;
  }
}
</style>
